import { css, Global } from '@emotion/react';
import React, { useState } from 'react';

import styled from '@emotion/styled';
import { mq, NavLink } from '@hangar31/rc-layout';
import { WithAuthPages } from 'graphql/withAuth';
import Image from 'next/image';
import Link from 'next/link';
import Cookies from 'universal-cookie';

import { ASSET_URL } from '@Usician/constants';

export const SidebarOpenBtnStyled = styled.button(
  () => css`
    @media (min-width: 1007px) {
      display: none;
    }
    @media (min-width: 767.98px) {
      font-size: 1.8rem;
    }
    position: relative;

    display: block;
    width: 100%;

    padding: 1.5rem 2rem;

    color: #7d7d7d;
    font-weight: bold;
    text-transform: uppercase;

    background-color: #e5e5e5;
    border: none;
    outline: none;

    &:after {
      position: absolute;
      top: 0.7rem;
      right: 2rem;

      padding: 0.5rem 0.5rem 1rem 0.5rem;

      font-size: 3rem;
      line-height: 1.4rem;
      text-align: center;

      border: 1px solid #c4c4c4;
      border-radius: 3px;

      transform: rotate(-90deg);
      content: '\\000BB';

      @media (min-width: 767.98px) {
        top: 1.1rem;

        font-size: 3.4rem;
      }
    }
  `
);
const SidebarStyled = styled.aside<{
  itemLength?: number;
  heading?: boolean;
  sub?: boolean;
  parent?: string;
  selected?: boolean;
  open?: boolean;
}>(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;

    width: 20rem;

    height: 100vh;

    margin: 0 0 0 0;

    background: ${theme.colors.offWhite};

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    transition: all 0.2s;

    div button {
      display: block;
      width: 100%;
      margin: 20px 0;
      padding-right: 4rem;

      color: ${theme.colors.hint};
      font-weight: bold;
      text-align: right;
      text-transform: uppercase;

      background: none;
      border: 0;
      outline: none;
      cursor: pointer;

      &.active,
      &:hover {
        color: ${theme.colors.black};
      }
    }

    @media (max-width: 1007px) {
      position: fixed;

      right: 0;
      bottom: 0;
      left: 0;

      z-index: 150;

      margin-right: 0;

      background-color: ${theme.colors.white};

      div {
        height: 0;
        overflow: hidden;

        transition: all 0.2s;

        @media (min-width: 767.98px) {
          button {
            font-size: 1.8rem;
          }
        }
      }

      box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.25);
    }
  `,
  ({ itemLength, open }) =>
    open &&
    itemLength &&
    css`
      div {
        position: static;

        height: ${itemLength * 35 + 30}px;
        @media (min-width: 767.98px) {
          height: ${itemLength * 45 + 30}px;
        }
      }
      ${SidebarOpenBtnStyled}:after {
        transform: rotate(90deg);
      }
    `
);

export const SidebarLinkStyled = styled.a<{
  heading?: boolean;
  selected?: boolean;
  parent?: string;
  sub?: boolean;
  itemLength?: number;
}>(
  ({ theme }) => css`
    display: block;
    width: 100%;
    margin: 2rem 0;
    margin-left: 2rem;
    padding-right: 3rem;
    padding-left: 1rem;

    color: ${theme.colors.hint};
    font-weight: 400;

    text-transform: none;

    cursor: pointer;
    &.active,
    &:hover {
      color: ${theme.colors.black};
    }
  `,
  ({ heading, theme }) =>
    heading &&
    css`
      margin: 0 0 1em;
      padding: 1rem 0;

      color: ${theme.colors.darkGray};
      font-weight: 700;

      text-align: center;
      text-transform: uppercase;

      background: ${theme.colors.stroke};

      pointer-events: none;
    `,
  ({ sub }) =>
    sub &&
    css`
      margin: 1rem 0;

      font-size: 1.1rem;
    `,
  ({ parent, selected, theme }) =>
    (selected || parent === 'true') &&
    css`
      color: ${theme.colors.black};

      border-left: 1px solid ${theme.colors.black};
    `
);

export const LogoStyled = styled.nav(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 6rem;

    padding: 1.5rem 1.5rem;

    background-color: ${theme.colors.navBg};
    img {
      max-width: 90%;
      max-height: 90%;
    }
  `,
  () => mq({ width: ['100%', , '20rem'] })
);

export default function AdminNav({
  resourcePages,
  settings,
}: {
  settings: Settings;
  resourcePages: WithAuthPages;
}) {
  const [open, setOpen] = useState(false);
  const logout = async () => {
    new Cookies().set('usician-token', '', { path: '/' });
    window.location.href = '/';
  };
  return (
    <>
      <button
        onClick={logout}
        type="button"
        css={{
          color: '#313131',
          position: 'absolute',
          right: '1.5rem',
          top: '1.5rem',
        }}
      >
        Sign Out
      </button>
      <Global styles={mq({ '#__next': { paddingLeft: [0, , '20rem'] } })} />
      <SidebarStyled
        open={open}
        onClick={() => {
          setOpen(!open);
        }}
        itemLength={4}
      >
        <LogoStyled>
          <Link passHref href="/member">
            <a>
              <Image
                priority
                width={420}
                height={80}
                src={ASSET_URL + settings?.appearance?.images?.logo}
                alt={settings?.information?.organization?.name}
              />
            </a>
          </Link>
        </LogoStyled>
        <SidebarOpenBtnStyled type="button">Menu</SidebarOpenBtnStyled>
        <div>
          <SidebarLinkStyled heading>Management</SidebarLinkStyled>
          <NavLink passHref href="/admin/lookup">
            <SidebarLinkStyled>Account Lookup</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/settings">
            <SidebarLinkStyled>Settings</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/automation">
            <SidebarLinkStyled>Automation</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/coupons">
            <SidebarLinkStyled>Coupons</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/meetings">
            <SidebarLinkStyled>Meetings</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/reports">
            <SidebarLinkStyled>Reports</SidebarLinkStyled>
          </NavLink>
          <NavLink passHref href="/admin/activity">
            <SidebarLinkStyled>Activity Log</SidebarLinkStyled>
          </NavLink>
          <SidebarLinkStyled heading>Resources</SidebarLinkStyled>
          {resourcePages
            ?.sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1))
            .map(page => (
              <NavLink
                passHref
                href={`/admin/resources/${page.slug}`}
                key={page.slug}
              >
                <SidebarLinkStyled>{page.name}</SidebarLinkStyled>
              </NavLink>
            ))}
          <NavLink passHref href="/admin/resources/new">
            <SidebarLinkStyled>&#43; Add Resource Page</SidebarLinkStyled>
          </NavLink>
        </div>
      </SidebarStyled>
    </>
  );
}
