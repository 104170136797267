import { HintStyled } from './Text.styled';

import { css } from '@emotion/react';
import React from 'react';

import styled from '@emotion/styled';
import { ErrorMsgs, SubmitButton } from '@hangar31/rc-form';
import Geosuggest from 'react-geosuggest';

export const BoxFormStyled = styled.section(
  ({ theme }) => css`
    align-self: center;
    width: 100%;
    max-width: 69rem;
    margin: auto;

    text-align: center;

    background-color: ${theme.colors.white};
    box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.1);
    justify-self: center;

    :before {
      display: block;
      content: '';

      height: 16rem;

      background-image: url('${process.env.NEXT_PUBLIC_ASSET_URL}${theme.images
        ?.banner}');
      background-position: center center;
      background-size: cover;
    }

    @media (max-width: 640px) {
      margin-top: 0;
      overflow: hidden;
      &:before {
        display: none;
      }
    }
  `
);

export const LabelStyled = styled.label(
  ({ theme }) => css`
    position: relative;

    display: block;
    margin: 0 0 2rem 0;
    + a,
    + ${HintStyled} {
      margin-top: -1.5rem;
    }
    &[aria-invalid='true'] + a,
    &[aria-invalid='true'] + ${HintStyled} {
      margin-top: 0;
    }
    > span {
      position: absolute;
      top: -0.4rem;
      left: 1.5rem;
      z-index: 10;

      padding: 0 0.7rem;

      color: ${theme.colors.hint};
      font-size: 1.1rem;

      background-color: white;
    }

    > button {
      position: absolute;
      top: 0rem;
      right: 0.5rem;
      z-index: 1;

      color: ${theme.colors.negative};

      font-size: 3rem;
    }
    input,
    select,
    textarea {
      position: relative;
      z-index: 1;

      display: block;
      width: 100%;
      min-height: 4.2rem;
      padding: 1rem 2rem;

      font-weight: bold;

      font-size: 1.4rem;
      line-height: 2rem;

      border: 0.1rem solid ${theme.colors.border};
      border-radius: 0.6rem;
      outline: none;

      &[disabled] {
        color: ${theme.colors.hint};

        background-color: ${theme.colors.offWhite};
      }
      &:focus {
        border-color: ${theme.colors.selected};
        outline: 0;
        box-shadow: 0;
      }
    }
    &[aria-invalid='true'] input,
    &[aria-invalid='true'] select,
    &[aria-invalid='true'] textarea {
      border-color: ${theme.colors.negative};
    }
    &[aria-invalid='false'] input,
    &[aria-invalid='false'] select,
    &[aria-invalid='false'] textarea {
      border-color: ${theme.colors.positive};
    }

    &[aria-invalid] input,
    &[aria-invalid] select,
    &[aria-invalid] textarea {
      &:focus {
        border-color: ${theme.colors.selected};
      }
    }
    &[readOnly] {
      border-color: ${theme.colors.border} !important;
    }
  `
);

export const ToggleStyled = styled.label<{
  falseText?: string;
  trueText?: string;
}>(
  ({ falseText = 'No', theme, trueText = 'Yes' }) => css`
    position: relative;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    min-height: 4.3rem;
    margin: 0 0 2rem 0;
    padding: 0 1rem;

    border: 0.1rem solid ${theme.colors.border};
    border-radius: 0.6rem;
    span {
      grid-column: 1;
      grid-row: 1;

      font-weight: bold;

      font-size: 1.2rem;
    }
    button {
      color: ${theme.colors.negative};

      font-size: 1.2rem;
    }
    input {
      min-width: 12rem;
      height: 3rem;
      margin: 0;

      visibility: hidden;
      &:before,
      &:after {
        display: inline-block;
        box-sizing: border-box;
        min-width: 6rem;
        height: 3rem;
        padding: 1rem;

        color: white;
        font-weight: bold;

        font-size: 1.2rem;
        line-height: 1.25rem;
        text-align: center;
        text-transform: uppercase;

        visibility: visible;
      }
      &:before {
        background-color: ${theme.colors.black};
        content: '${falseText}';
      }
      &:after {
        background-color: ${theme.colors.stroke};

        content: '${trueText}';
      }
      &:checked:before {
        background-color: ${theme.colors.stroke};
      }
      &:checked:after {
        background-color: ${theme.colors.black};
      }
    }
  `
);

export const ButtonStyled = styled.button<{
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  fullBox?: boolean;
  charged?: boolean;
}>(
  ({ theme }) => css`
    display: block;
    max-width: 100%;
    margin: 0;
    padding: 1.2rem 1.5rem;

    color: ${theme.colors.btnLink};
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;

    background-color: ${theme.colors.btnBg};
    border: none;
    border-radius: 0.3rem;

    outline: none;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background-color: ${theme.colors.btnHover};
    }
  `,
  ({ lg }) =>
    lg &&
    css`
      display: block !important;
      width: 100%;

      margin-top: 3rem;
      padding: 2rem 2.5rem;

      font-size: 4.8rem;
      text-align: right;

      border-radius: 0;

      @media (max-width: 640px) {
        font-size: 2.4rem;
      }
    `,
  ({ md }) =>
    md &&
    css`
      width: 100%;
      margin-top: 1.5rem;
      padding: 1rem 2rem;

      font-size: 2.4rem;
      text-align: right;
    `,
  ({ sm }) =>
    sm &&
    css`
      width: 100%;
      padding: 1rem 2rem;

      font-size: 1.2rem;
    `,
  ({ fullBox }) =>
    fullBox &&
    css`
      width: calc(100% + 5rem);
      margin: 1.5rem -2.5rem -2rem;
    `,
  ({ charged, theme }) =>
    charged !== undefined &&
    css`
      background-color: ${charged
        ? theme.colors.negative
        : theme.colors.positive} !important;

      &:hover {
        background-color: ${theme.colors.darkGray};
      }
    `
);

export const SubmitButtonStyled = ({
  children,
  ...props
}: React.PropsWithChildren<Record<string, any>>) => {
  return (
    <SubmitButton
      as={ButtonStyled}
      stateCSS={{
        WAITING: {
          opacity: '0.3',
          pointerEvents: 'none',
        },
      }}
      {...props}
    >
      {children}
    </SubmitButton>
  );
};

export const AddressInputStyled = styled(Geosuggest)(
  ({ theme }) => css`
    .geosuggest__input {
      width: 100%;
      padding: 1rem 2rem;

      font-weight: bold;

      font-size: 1.4rem;
      line-height: 2rem;

      border: 0.1rem solid ${theme.colors.stroke};
      outline: none;
    }
    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 100;

      max-height: 25em;
      margin-top: -4px;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;

      list-style: none;
      background: #fff;
      border: 0.1rem solid ${theme.colors.selected};
      border-top: none;
      border-top-width: 0;

      transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;

      border-width: 0;
      box-shadow: none;
    }

    /**
 * A geosuggest item
 */
    .geosuggest__item {
      padding: 0.5em 0.65em;

      color: ${theme.colors.black};

      font-size: 1.4rem;

      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #f5f5f5;
    }
    .geosuggest__item--active {
      color: #fff;

      background: #267dc0;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
  `
);

export const CheckboxLabelStyled = styled.label(
  ({ theme }) => css`
    position: relative;

    width: 100%;
    small {
      position: static;

      padding: 0 0.5rem;
    }
    span {
      margin-right: 2rem;

      color: ${theme.colors.hint};

      font-weight: bold;

      font-size: 1.2rem;
      line-height: 1.4rem;
      text-transform: uppercase;

      cursor: pointer;
    }

    input {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      min-height: 0;
      margin: -0.5rem 1.4rem 0rem 0;

      line-height: 1.4rem;

      vertical-align: middle;

      visibility: hidden;
      cursor: pointer;
      &:before {
        display: block;
        width: 100%;
        height: 100%;

        border: 1px solid ${theme.colors.darkGray};
        visibility: visible;
        content: '';
      }
      &:checked:before {
        background: ${theme.colors.darkGray};
      }
    }
    &[aria-invalid='true'] {
      input:before {
        border-color: ${theme.colors.negative};
      }
    }
  `
);

export const BorderedBoxStyled = styled.div<{ sm?: boolean }>(
  ({ theme }) => css`
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 2rem 2.5rem;

    border: 0.1rem solid ${theme.colors.stroke};
    border-radius: 0.6rem;
  `,
  ({ sm }) =>
    sm &&
    css`
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
    `
);

export const TextDisclaimerStyled = styled.blockquote(
  ({ theme }) => css`
    max-height: 10rem;
    margin: 0 0 2rem;
    overflow-y: auto;

    color: ${theme.colors.hint};
    text-align: left;
  `
);

export const ImageLabelStyled = styled(LabelStyled)(
  ({ theme }) => css`
    display: flex;
    align-items: stretch;

    background: #f4f4f4;

    border: 1px solid ${theme.colors.stroke};
    border-radius: 0.6rem;
    label {
      height: auto;

      cursor: pointer;
    }
    button {
      top: -2.5rem;

      font-size: 1rem;
    }
    span {
      top: -1.5rem;
      left: -0.5rem;
    }
  `
);

export const FileLabelStyled = styled(LabelStyled)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    min-height: 4.2rem;
    padding: 0.5rem;
    padding: 1rem 2rem;

    border: 1px solid ${theme.colors.border};
    border-radius: 0.6rem;
    div {
      width: 100%;

      label {
        cursor: pointer;
      }
      span {
        font-size: 1.25em;
      }
    }
    button {
      margin-left: 0.5rem;

      color: ${theme.colors.negative};
      font-size: 0.9em;
      line-height: 1em;
      vertical-align: 0px;
    }
    div &[aria-invalid='true'] {
      border-color: ${theme.colors.negative};
    }
    &[aria-invalid='false'] {
      border-color: ${theme.colors.positive};
    }
  `
);

export const InlineFormStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 1rem;

    background-color: ${theme.colors.white};
    border-radius: 0.6rem;

    ${LabelStyled} {
      margin-right: 1rem;
      margin-bottom: 0;
    }

    ${ButtonStyled} {
      color: ${theme.colors.hint};

      background-color: ${theme.colors.stroke};
    }
  `
);

export const ErrorMsgsStyled = styled(ErrorMsgs)(
  ({ theme }) => css`
    color: ${theme.colors.negative};

    list-style-type: '\u26A0';
  `
);
