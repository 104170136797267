import { css, Global } from '@emotion/react';
import React from 'react';

import { defaultStyles } from '@hangar31/rc-layout';
import { UsicianTheme } from 'theme';

export default function GlobalStyled({ theme }: { theme: UsicianTheme }) {
  return (
    <Global
      styles={css`
        ${defaultStyles}

        body {
          color: #4c4c4c;
          font-size: 1.2rem;
          font-family: Roboto, sans-serif;
          line-height: 1.2;
        }

        #__next {
          padding-top: 7rem;

          background-color: #f3f3f3;
          background-size: cover;
          background-attachment: fixed;
        }
        /* remove auto complete color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          box-shadow: 0 0 0 30px white inset !important;
        }

        textarea {
          font-family: Roboto, sans-serif;
        }

        button {
          font-family: Roboto, sans-serif;

          transition: all 0.2s;
        }
        a {
          color: ${theme.colors.active};
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;

          cursor: pointer;

          &:hover {
            color: ${theme.colors.activeHover};
          }
        }

        select {
          background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
            no-repeat 99.5% 50%;
          cursor: pointer;

          appearance: none;
        }

        .error {
          position: absolute;
          right: 0;

          display: inline-block;
          padding: 0.5rem;

          color: ${theme.colors.negative};
        }
        .sorting {
          cursor: grabbing;

          pointer-events: auto !important;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
          margin-bottom: 0.5rem;

          font-size: 1.4rem;
        }
        .react-datepicker__header__dropdown {
          margin-bottom: 0.5rem;
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 2.2rem;
        }
        .react-datepicker__navigation--years {
          padding: 0.6rem 0;
        }
        .react-datepicker__navigation--years-previous {
          top: 0.7rem;
        }
        .react-datepicker__navigation--years-upcoming {
          top: -0.7rem;
        }
        .react-datepicker {
          font-size: 1.3rem;
        }
        .react-datepicker-wrapper {
          display: block;
          width: 100%;
          input {
            font-family: Roboto, sans-serif;
            &::placeholder {
              font-weight: 400;
            }
          }
        }
        .react-datepicker-popper {
          z-index: 100;
        }
        [type='color'] {
          cursor: pointer;
        }
      `}
    />
  );
}
