import 'rc-slider/assets/index.css';
import 'rc-dialog/assets/index.css';
import { ButtonStyled } from '@Usician/styles/Form.styled';
import GlobalStyled from '@Usician/styles/Global.styled';

import { css, Global, ThemeProvider } from '@emotion/react';
import React, { ReactElement } from 'react';

import { AppPropsType } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GoogleFonts } from 'next-google-fonts';
import UsicianTheme from 'theme';
import '@hangar31/built-by-h31';

import AdminNav from '@Usician/nav/AdminNav';
import MemberNav from '@Usician/nav/MemberNav';
import PublicNav from '@Usician/nav/PublicNav';

function MyApp({
  Component,
  pageProps: { seo = {}, layout, ...pageProps },
}: AppPropsType): ReactElement {
  const theme = {
    ...UsicianTheme,
    ...pageProps?.settings?.appearance,
    colors: {
      ...UsicianTheme.colors,
      ...pageProps?.settings?.appearance?.colors,
    },
    images: {
      ...UsicianTheme.images,
      ...pageProps?.settings?.appearance?.images,
    },
  };
  const router = useRouter();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyled theme={theme} />
      <GoogleFonts href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700&display=swap" />
      <Head>
        <meta
          name="description"
          content={seo.pageDescription || 'usician-frontend'}
        />
        <title>{seo.pageTitle || 'usician-frontend'}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDolqNk30Vsu0c90UedBmn_I6gxtdjaJPU&libraries=places"
          key="google-api"
          async
        />
        {/* <link rel="manifest" href="/manifest.json" /> */}
        <link
          href="/favicon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/apple-icon.png" />
        <meta name="theme-color" content="#317EFB" />
      </Head>

      {layout === 'PUBLIC' && <PublicNav {...pageProps} />}
      {(layout === 'MEMBER' || layout === 'MEMBER_ACCOUNT') && (
        <MemberNav {...pageProps} />
      )}
      {layout === 'ADMIN' && <AdminNav {...pageProps} />}
      {(layout === 'PUBLIC' ||
        layout === 'MEMBER' ||
        layout === 'APPLICATION') && (
        <Global
          styles={css`
            #__next {
              background-image: url(${process.env.NEXT_PUBLIC_ASSET_URL +
              pageProps?.settings?.appearance?.images?.background});
            }
          `}
        />
      )}
      {layout === 'ADMIN' && (
        <Global
          styles={css`
            #__next {
              padding-bottom: 12rem;
            }

            #__next ${ButtonStyled} {
              background-color: ${theme.colors.darkGray};
              &:hover {
                background-color: ${theme.colors.stroke};
              }
            }
          `}
        />
      )}
      {pageProps?.user?.localInfo?.authRole === 'ADMIN' && (
        <div
          css={{
            background: '#fff',
            borderRadius: '0.6rem',
            bottom: '2rem',
            left: '2rem',
            padding: '0.6rem',
            position: 'fixed',
          }}
        >
          <Link
            passHref
            href={layout === 'ADMIN' ? '/member' : '/admin/lookup'}
          >
            <a css={{ color: '#747474' }}>{`Switch To ${
              layout === 'ADMIN' ? 'Member' : 'Admin'
            } View`}</a>
          </Link>
        </div>
      )}
      <Component {...pageProps} key={router.asPath} />
    </ThemeProvider>
  );
}

export default MyApp;
