import React, { ReactElement } from 'react';

function ProfileSVG(props: {
  fill?: string;
  width: string;
  height?: string;
}): ReactElement {
  const { fill = 'currentColor', width = '100%', height = width } = props;

  return (
    <i css={{ height, width }}>
      <svg
        height={height}
        width={width}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none">
          <path
            d="M20 18.571A3.286 3.286 0 1 0 20 12a3.286 3.286 0 0 0 0 6.571zm0 1A4.286 4.286 0 1 0 20 11a4.286 4.286 0 0 0 0 8.571z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            d="M24.998 26.3c-.029.037-.076.09-.15.158-.214.2-.566.443-1.048.68-.959.474-2.308.862-3.8.862s-2.841-.388-3.8-.861c-.482-.238-.834-.482-1.049-.68a1.388 1.388 0 0 1-.15-.159 5 5 0 0 1 9.997 0zm.037-.053l-.003.005a.04.04 0 0 1 .003-.005zm-10.07 0l.003.005a.03.03 0 0 1-.003-.005zM20 29c3.314 0 6-1.714 6-2.571a6 6 0 0 0-12 0C14 27.286 16.686 29 20 29z"
            fill={fill}
            fillRule="evenodd"
          />
          <circle cx="20" cy="20" r="14.5" stroke={fill} />
        </g>
      </svg>
    </i>
  );
}

export default ProfileSVG;
