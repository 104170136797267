export const ASSET_URL = process.env.NEXT_PUBLIC_ASSET_URL || '';
export const GRAPHQL_URI = process.env.NEXT_PUBLIC_GRAPHQL_URI || '';
export const WORK_VISA_TYPE = {
  B1: 'B1',
  EAD: 'EAD',
  GREEN_CARD: 'Green Card',
  H2B: 'H2B',
  O1: 'O1',
  OTHER: 'Other',
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
};
