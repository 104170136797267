import { css } from '@emotion/react';

import styled from '@emotion/styled';

export const NavStyled = styled.nav(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    z-index: 1000;

    width: 100%;

    padding: 1.5rem 1.5rem;

    background-color: ${theme.colors.navBg};
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `
);

export const NavLinkStyled = styled.a<{
  selected?: boolean;
  admin?: boolean;
}>(
  ({ theme }) => css`
    padding: 0 3rem;

    color: ${theme.colors.navLink};
    text-decoration: none;
    vertical-align: middle;

    &:hover {
      color: ${theme.colors.navHover};
    }
  `,
  ({ selected, theme }) =>
    selected &&
    css`
      color: ${theme.colors.navHover};
    `,
  ({ admin }) =>
    admin &&
    css`
      color: #747474;
    `,
  ({ admin, selected }) =>
    admin &&
    selected &&
    css`
      color: #fff;
    `
);
