import { css } from '@emotion/react';

import styled from '@emotion/styled';

export const Heading1Styled = styled.h1(
  () => css`
    margin: 20px 0;

    font-size: 2.4rem;
    line-height: 4rem;
    text-transform: uppercase;
  `
);

export const Heading2Styled = styled.h2(
  ({ theme }) => css`
    display: flex;
    grid-column: 1 / -1;
    align-items: center;
    padding-bottom: 10px;

    color: ${theme.colors.black};
    font-weight: bold;
    font-size: 1.8rem;
    text-align: left;
    text-transform: uppercase;

    border-bottom: 1px solid ${theme.colors.hint};
    button {
      display: inline-block;
      padding: 0;

      color: ${theme.colors.hint};

      line-height: 1em;

      &:first-of-type {
        margin-left: 1.5rem;
      }
      &:last-of-type {
        margin-left: auto;
      }
    }
  `
);

export const Heading3Styled = styled.h3(
  ({ theme }) => css`
    grid-column: 1 / -1;

    color: ${theme.colors.hint};
    font-weight: bold;
    font-size: 1.6rem;
    text-align: left;
    text-transform: uppercase;
  `
);

export const HintStyled = styled.p(
  ({ theme }) => css`
    margin: 1rem 0 2rem;

    color: ${theme.colors.hint};
    font-size: 1.2rem;
    text-align: left;
  `
);

export const ChargedText = styled.span<{ positive?: boolean }>(
  ({ positive, theme }) => css`
    color: ${positive === true ? theme.colors.positive : theme.colors.negative};
    text-transform: uppercase;
  `
);
