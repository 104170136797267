import { NavLinkStyled, NavStyled } from '@Usician/styles/Nav.styled';

import { css } from '@emotion/react';
import React from 'react';

import { MaxWidthStyled, NavLink } from '@hangar31/rc-layout';
import Image from 'next/image';

import { ASSET_URL } from '@Usician/constants';

function PublicNav({ settings }: { settings: Settings }) {
  return (
    <NavStyled>
      <MaxWidthStyled>
        <div>
          <Image
            width={210}
            height={40}
            src={ASSET_URL + settings?.appearance?.images?.logo}
            css={css`
              max-height: 4rem;
            `}
            alt={settings?.information?.organization?.name}
          />
          <NavLinkStyled
            href={settings?.information?.organization?.websiteLink}
          >
            « {settings?.information?.organization?.websiteText}
          </NavLinkStyled>
        </div>
        <div>
          <NavLink passHref href="/">
            <NavLinkStyled>Login</NavLinkStyled>
          </NavLink>

          <NavLink passHref href="/sign-up">
            <NavLinkStyled>Sign Up</NavLinkStyled>
          </NavLink>
        </div>
      </MaxWidthStyled>
    </NavStyled>
  );
}

export default PublicNav;
