export const theme = {
  colors: {
    active: '#000000',
    activeHover: '#000000',
    black: '#000000',
    border: '#E7E7E7',
    btnBg: '#000000',
    btnHover: '#000000',
    btnLink: '#000000',
    darkGray: '#313131',
    hint: '#7D7D7D',
    navBg: '#000000',
    navHover: '#000000',
    navLink: '#000000',
    negative: '#D10505',
    offWhite: '#FDFDFD',
    positive: '#2EAF00',
    selected: '#B0B0B0',
    stroke: '#D7D7D7',
    white: '#FFFFFF',
  },
  images: {
    banner: '',
  },
};

export default theme;

export type UsicianTheme = typeof theme;
