import { NavLinkStyled, NavStyled } from '@Usician/styles/Nav.styled';

import { css, Global } from '@emotion/react';
import React, { useState } from 'react';

import styled from '@emotion/styled';
import { MaxWidthStyled, NavLink } from '@hangar31/rc-layout';
import { WithAuthCleanUser, WithAuthPages } from 'graphql/withAuth';
import Image from 'next/image';
import Link from 'next/link';
import Cookies from 'universal-cookie';

import { ASSET_URL } from '@Usician/constants';

import ProfileSVG from '../svgs/ProfileSVG';

export const ProfileDropdownStyled = styled.div<{ open?: boolean }>(
  ({ open, theme }) => css`
    position: absolute;
    top: 0;
    right: -1.5rem;
    z-index: ${open ? '2' : '1'};

    display: flex;
    justify-content: flex-end;

    width: ${open ? '25rem' : 'auto'};

    margin: -0.5rem;

    padding: 0.5rem;

    text-align: right;
    a,
    button,
    small {
      font-size: 1.4rem;
    }
    a,
    button {
      line-height: 2.6rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0.7rem;

      color: ${theme.colors.navLink};

      background-color: ${theme.colors.navBg};
      i {
        margin-right: -2rem;
      }
      &:hover {
        color: ${theme.colors.white};

        background-color: ${theme.colors.navHover};
      }
    }

    > span:first-child {
      position: relative;
      z-index: 5;

      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      padding-right: 1.6rem;
      padding-left: 1.6rem;

      i {
        margin-right: 0;
        margin-left: auto;

        color: ${open ? theme.colors.black : theme.colors.navLink};

        cursor: pointer;

        transition: all 0.2s;
        &:hover {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    small {
      display: ${open ? 'block' : 'none'};
      flex: 1;
      padding-left: 0.4rem;

      color: ${theme.colors.black};
      text-align: left;
    }

    &:before {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      display: ${open ? 'block' : 'none'};

      content: '';
    }

    > span:nth-of-type(2) {
      position: absolute;

      top: 0;
      right: 0;
      left: 0;

      z-index: 4;

      display: ${open ? 'flex' : 'none'};
      flex-wrap: wrap;
      padding: 4.5rem 0 0 0;
      overflow: hidden;

      text-align: center;

      background: ${theme.colors.white};
      border-radius: 0.6rem;

      a {
        display: block;
        flex: 1;
        align-items: center;
        padding: 0.7rem 2.4rem;

        color: ${theme.colors.active};
        text-align: left;

        i {
          margin-right: 1.3rem;

          color: ${theme.colors.active};

          vertical-align: middle;
        }

        &:hover {
          color: ${theme.colors.activeHover};
        }

        &:hover i {
          color: ${theme.colors.activeHover};
        }
      }
    }
  `
);

function MemberNav({
  resourcePages,
  settings,
  user,
}: {
  settings: Settings;
  resourcePages: WithAuthPages;
  user: WithAuthCleanUser;
}) {
  const logout = async () => {
    new Cookies().set('usician-token', '', { path: '/' });
    window.location.href = '/';
  };
  const [profileDropdown, setProfileDropdown] = useState(false);

  return (
    <NavStyled>
      <Global
        styles={css`
          #__next {
            padding-top: 11rem;
          }
        `}
      />
      <MaxWidthStyled>
        <div>
          <NavLink passHref href="/member">
            <a>
              <Image
                width={210}
                height={40}
                src={ASSET_URL + settings?.appearance?.images?.logo}
                css={css`
                  max-height: 4rem;
                `}
                alt={settings?.information?.organization?.name}
              />
            </a>
          </NavLink>
          <NavLinkStyled
            href={settings?.information?.organization?.websiteLink}
          >
            « {settings?.information?.organization?.websiteText}
          </NavLinkStyled>
        </div>
        <div>
          <NavLink passHref href="/member">
            <NavLinkStyled>Dashboard</NavLinkStyled>
          </NavLink>

          <NavLink passHref href="/member/directory">
            <NavLinkStyled>Directory</NavLinkStyled>
          </NavLink>
          {resourcePages
            ?.sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1))
            ?.map(page => (
              <NavLink
                passHref
                href={`/member/resources/${page.slug}`}
                key={page.slug}
              >
                <NavLinkStyled>{page.name}</NavLinkStyled>
              </NavLink>
            ))}
          <div css={{ height: 40, position: 'relative', width: 40 }}>
            <ProfileDropdownStyled
              open={profileDropdown}
              onClick={() => setProfileDropdown(!profileDropdown)}
            >
              <span>
                <small>{`${user.firstName} ${user.lastName}`}</small>
                <ProfileSVG width="40" />
              </span>
              <span>
                <Link passHref href="/member/billing">
                  <a>Billing Settings</a>
                </Link>
                <button onClick={logout} type="button">
                  Sign Out
                </button>
              </span>
            </ProfileDropdownStyled>
          </div>
        </div>
      </MaxWidthStyled>
    </NavStyled>
  );
}

export default MemberNav;
